<template>
  <div class="flex flex-auto flex-wrap lg:flex-nowrap ">
    <div class="md:w-3 mr-4 flex flex-auto flex-column border-round surface-0">
      <IssuesList :enableSelected="true" :selecteditempkey="selectedFeedPkey" @issueItemSelected="onFeedSelected" />
    </div>
    
    <div class="md:w-9 flex flex-auto flex-column border-round " :class="{'surface-details-top' : m_SelectedFeed}">
      <div v-if="m_SelectedFeed" class="flex flex-none flex-column p-4" >
        <div class="text-sm text-sm text-dimmed">Meldung #{{selectedFeedPkey}} {{selectedFeedTime}}</div>
        <div class="mb-2 text-xl font-bold">{{m_SelectedFeed.label}}{{selectedFeedPlant}}</div>
        <div class="text-base text-primary">{{selectedFeedRefTextLine}}</div>
        <div class="my-2"></div>
        <div class="mb-3">{{selectedFeedLastIssueMessage}}</div>
        
        <Fieldset class="pt-2" legend="Feedback">
            <span class="p-float-label mb-3">
                <InputText class="w-full" id="feedbackcomment" type="text" v-model="m_FeedbackComment" />
                <label for="feedbackcomment">Kommentar</label>
            </span>
            <div v-if="m_SelectedFeed.feedbackmask != 0" class="mb-3">
              <FeedbackItem v-if="needFeedback1" question="Trifft dieses Meldung zu?" v-model:answer="m_Feedback1Value" />
              <FeedbackItem v-if="needFeedback2" question="Wurden Maßnahmen ergriffen?" v-model:answer="m_Feedback2Value" />
            </div>
            <Button label="Absenden" class="p-button-raised" @click="onClickSendFeedback(m_SelectedFeed)"  />
        </Fieldset>
        
      </div>

      <div v-if="m_SelectedFeed" class="flex flex-auto flex-column p-4 surface-details-bottom border-round-bottom">
        <div class="scrollable-flex">

          <div v-if="!loadingFeed" class="mt-2">
            <div v-if="hasCurrentIssueReactions" class="mb-4">
              <history-entry-reaction v-for="(reaction) in currentIssueReactions" :key="reaction.pkey" :reaction="reaction" class="ml-3 mb-2" />
            </div>

            <div v-if="hasHistory" class="mx-6" >
              <h3>Verlauf</h3>
              <history-entry-issue v-for="(issue) in m_IssuesHistory" :key="issue.pkey" class="mb-4" :issue="issue" />
            </div>
          </div>

      </div>

      </div>
    </div>

  </div>
</template>

<script>
import IssuesList from './IssuesList.vue';
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRestApi } from "../RESTService";
import { processErrorResponseWithToast, timestampToString } from "../epcommon";
import FeedbackItem from './FeedbackItem.vue';

export default {
  name: "IssuesPage",
  components: { IssuesList, FeedbackItem },
  props: {
    displayfeedkey: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const store = useStore();
    const m_SelectedFeed = ref(null);
    const m_CurrentIssueDetails = ref(null);
    const m_IssuesHistory = ref([]);
    
    const m_Feedback1Value = ref(0);
    const m_Feedback2Value = ref(0);
    const m_FeedbackComment = ref("");

    const loadingFeed = ref(false);
    const restService = useRestApi();

    let m_componentMounted = false;
    let m_componentInitialized = false;
    
    onMounted(() => {
        m_componentMounted = true;
        store.dispatch("refreshFeeds"); 
        initWhenReady();
    });

    watch(() => store.getters.isready, function () { initWhenReady(); });
    watch(() => props.displayfeedkey, () => { selectFeedByPkey(props.displayfeedkey); });

    const initWhenReady = async () => {
        if (m_componentInitialized || !m_componentMounted || !store.getters.isready) return;
        m_componentInitialized = true;

        selectFeedByPkey(props.displayfeedkey);
    }

    const fetchFeed = async (feedpkey) => {
        loadingFeed.value = true;
        restService.getFeedHistory(feedpkey).then((response) => {
          //console.log("Server responded OK to getFeedHistory: " + JSON.stringify(response));
          resetFeedback();
          //context.commit("setfeeds", response);
          if (response.length > 0) {
            m_CurrentIssueDetails.value = response[0];
            if (response.length > 1) {
              m_IssuesHistory.value = response.slice(1);
            }
          }
          loadingFeed.value = false;
        }).catch((error) => {loadingFeed.value = false; processErrorResponseWithToast(error, store); } );
    }

    const selectFeedByPkey = (feedpkey) => {
      if (feedpkey > 0) {
        let feed = store.getters.findFeedByPkey(feedpkey);
        if (feed) onFeedSelected(feed);
      }
    }

    const resetFeedback = () => { 
      m_FeedbackComment.value = "";
      m_Feedback1Value.value = 0;
      m_Feedback2Value.value = 0;
    }

    const onFeedSelected = (feed) => { 
        if (m_SelectedFeed.value)
        {
          if (m_SelectedFeed.value?.pkey == feed.pkey) return; // ignore the click on the same feed
        }

        m_SelectedFeed.value = feed;
        m_IssuesHistory.value = [];
        m_CurrentIssueDetails.value = null;
        if (feed?.pkey > 0) {
          loadingFeed.value = true;
          fetchFeed(m_SelectedFeed.value.pkey);
        }
    };

    const hasCurrentIssueReactions = computed( () => { 
        return currentIssueReactions.value.length > 0;
    });

    const currentIssueReactions = computed( () => { 
        if (m_SelectedFeed.value && m_CurrentIssueDetails.value) {
          return (m_CurrentIssueDetails.value?.reactions ?? []);
        }
        return [];
    });

    const hasHistory = computed( () => {
      return (m_IssuesHistory.value.length > 0);
    });

    const selectedFeedPkey = computed( () => { 
        if (m_SelectedFeed.value) {
          return m_SelectedFeed.value.pkey ?? 0;
        }
        return 0;
    });

    const selectedFeedTime = computed( () => { 
        if (m_SelectedFeed.value) {
          return timestampToString(m_SelectedFeed.value.timestamp ?? "", true);
        }
        return 0;
    });

    const selectedFeedPlant = computed( () => { 
        if (m_SelectedFeed.value) {
            let pid = m_SelectedFeed.value.plantid ?? "";
            if (pid.length > 0) return " in "+pid;
        }
        return "";
    });

    const selectedFeedRefTextLine = computed( () => { 
        let txt = selectedFeedSensorAddressText.value;
        let gatewaykey = m_SelectedFeed.value?.gatewaykey ?? 0;
        if (gatewaykey > 0) {
          if (txt.length > 0) txt += ", ";
          let gw = store.getters.findGatewayByPkey(gatewaykey);
          txt += "Basisstation " + (gw ? gw.id : gatewaykey);
        }
        let agriculturekey = m_SelectedFeed.value?.agriculturekey ?? 0;
        if (agriculturekey > 0)
        {
          if (txt.length > 0) txt += ", ";
          txt += "bei " + store.getters.getAgricultureNameByPkey(agriculturekey);
        }
        return txt;
    });

    const selectedFeedSensorAddressText = computed( () => { 
        let sensor = store.getters.findSensorByPkey(m_SelectedFeed.value?.sensorkey);
        if (sensor) {
            let stxt = store.getters.getSensorAddressText(sensor);
            if (stxt.length > 0) stxt = " in " + stxt;
            return "Sensor " + sensor.id;
        }
        /*else {
          console.log("NO Sensor found for feed "+m_SelectedFeed.value?.pkey);
        }*/
        return "";
    });

    const selectedFeedLastIssueMessage = computed( () => { 
        return m_SelectedFeed.value?.message ?? "";
    });

    const needFeedback1 = computed( () => {
      let mask = m_CurrentIssueDetails.value?.feedbackmask ?? 0;
      return ((mask & 1) > 0);
    });

    const needFeedback2 = computed( () => {
      let mask = m_CurrentIssueDetails.value?.feedbackmask ?? 0;
      return ((mask & 2) > 0);
    });

    const needFeedback3 = computed( () => {
      let mask = m_CurrentIssueDetails.value?.feedbackmask ?? 0;
      return ((mask & 4) > 0);
    });

    const onClickSendFeedback = (feed) => {
      let sComment = m_FeedbackComment.value.trim();
      let req = {comment:sComment, feedback:{}};
      if (m_Feedback1Value.value > 0) {
        req.feedback.correct = (1 == m_Feedback1Value.value);
      }

      if (m_Feedback2Value.value > 0) {
        req.feedback.acted = (1 == m_Feedback2Value.value);
      }

      if (sComment.length > 0 || Object.keys(req.feedback).length > 0) {
//        console.log("Send feedback for issue " + feed.issuekey + ": " + JSON.stringify(req));

        resetFeedback();
        restService.sendIssueReaction(feed.issuekey,req).then((/*response*/) => {
          fetchFeed(m_SelectedFeed.value?.pkey ?? 0);
        }).catch((error) => processErrorResponseWithToast(error, store));


      }
      else {
        store.commit("seterror", {caption : "Leeres Feedback", message: "Bitte machen Sie Feedback-Eingaben vor dem Absenden", duration: 6000} );
      }
    };

    return { selectedFeedPkey, selectedFeedTime, loadingFeed, onFeedSelected,
           m_SelectedFeed,
           selectedFeedPlant,
           selectedFeedRefTextLine,
           selectedFeedLastIssueMessage,
           hasCurrentIssueReactions, currentIssueReactions, hasHistory,
           m_IssuesHistory,
           needFeedback1,needFeedback2,needFeedback3,
           m_FeedbackComment, m_Feedback1Value, m_Feedback2Value,
           onClickSendFeedback };
  },
};
</script>

<style scoped>

.surface-details-top {
  background-color: var(--surface-b);
}

.surface-details-bottom {
  background-color: var(--surface-a);
}

</style>

