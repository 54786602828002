<template>
    <div class="flex flex-none align-items-center" style="height: 3.0em">
        <div class="font-bold mr-2">{{question}}</div>
        <Button label="Ja" class="p-button-text p-button-plain mr-2" @click="onClickYes" 
            :class="{'selected-option selected-option-yes' : isYes, 'alternative-option' : isNo}" />

        <Button label="Nein" class="p-button-text" @click="onClickNo"
            :class="{'selected-option selected-option-no' : isNo, 'alternative-option' : isYes}" />

    </div>
</template>

<script>
import { ref, computed, onMounted, watch} from  "vue";

export default ({
    name: "FeedbackItem",
    components: {  },
    props: {
        question: {
            type: String,
            default: ""
        },
        answer: {
            type: Number,
            default: 0
        },
    },
    emits: ["update:answer"],
    setup(props, context) {
        
        const m_SelectedOption = ref(0);

        onMounted(() => {
            m_SelectedOption.value = props.answer;
        });

        watch(() => props.answer, function () {  m_SelectedOption.value = props.answer; });

        const onClickYes = () => { 
            if (m_SelectedOption.value == 1) m_SelectedOption.value = 0;
            else m_SelectedOption.value = 1;
            context.emit('update:answer', m_SelectedOption.value);
        };

        const onClickNo = () => { 
            if (m_SelectedOption.value == 2) m_SelectedOption.value = 0;
            else m_SelectedOption.value = 2;
            context.emit('update:answer', m_SelectedOption.value);
        };

        const isYes = computed(() => {
            return (1 == m_SelectedOption.value);
        });

        const isNo = computed(() => {
            return (2 == m_SelectedOption.value);
        });

        return { isYes, isNo, onClickYes, onClickNo }
    }
})
</script>

<style scoped>

.selected-option {
    color: var(--primary-color);
    font-size: 1.25rem;
    font-weight: 700;
}

.selected-option-no, .selected-option-no:hover {
    color: red;
}

.alternative-option {
    display: none;
}

</style>

